size(w, h)
  width w
  height h

flexCenter()
  display flex
  justify-content center
  align-items center

cover()
  background-position center center
  background-size cover
  background-repeat no-repeat


