// Familia Section
#familia-section
  color $dark
  font-size 14px
  .container
    @media(max-width $md)
      padding-left 10px
      padding-right 10px
  
  .row
    row()
    @media(max-width $sm)
      row(-10px)
  .column
    column(50%)
    @media(max-width $sm)
      column(100%, 10px)
  
  .app-section-title
    margin-bottom 30px
    color $secondary
  
  img
    max-width 100%
    &.show-sm
      display none
      @media(max-width $sm)
        display inline-block
        width 100%
        margin-bottom 10px
    &.hide-sm
      @media(max-width $sm)
        display none
        
  
  p
    text-align justify
    line-height 23px
    

// Cursos & Agenda Section
#ca-section
  .container
    @media(max-width $md)
      padding 10px
  
  .row
    row()
    @media(max-width $md)
      row(-10px)
  .column
    column(50%)
    @media(max-width $md)
      column(100%, 10px)
      & + .column
        margin-top 30px

  .app-section-title
    margin-bottom 40px
  
  .btn-block
    margin-top 8px
  
  .card
    wrapper()
    background-color #FFF
    position relative
    padding 25px 34px
    & + .card
      margin-top 8px
    
    &.primary
      color $primary_hover
    &.secondary
      color $secondary
    
    &-title-agenda
      font-weight 700
      text-transform uppercase
      font-size 15px
      margin-top 14px
    
    &-action
      position absolute
      bottom 25px
      right 34px
      width 132px
      height 38px
      @media(max-width $sm)
        width 100%
        bottom 0
        right 0
        position relative
        margin-top 14px
    
    &-calendar-agenda
      display inline-block
      width 100%
      color $dark
      position relative
      padding-left 43px
      margin-top 14px
      strong,
      small
        display block
      strong
        font-size 15px
        font-weight 900
      small 
        font-size 12px
        font-weight 700

      &:before
        content ''
        position absolute
        width 35px
        height 35px
        background-size 35px 35px
        background-repeat no-repeat
        background-image url('../images/icon-calendario.png')
        top calc(50% - 17.5px)
        left 0

// Blog Section
#blog-section
  padding-bottom 0
  .blog-button
    text-align center
    margin-top 42px
    .btn
      width 542px
      @media(max-width $sm)
        width 100%
  .container
    @media(max-width $md)
      padding 10px
  .row
    row(-20px)
    @media(max-width $sm)
      row(-10px)
    .column
      column(33.333%, 20px)
      margin 20px 0
      @media(max-width $sm)
        column(50%, 10px)
        margin 10px 0
      @media(max-width $xs)
        column(100%, 10px)