.curso-share
  wrapper()
  display flex
  flex-flow row wrap
  align-items center
  justify-content space-between
  background-color $alt
  padding 36px 45px
  @media(max-width $md)
    padding 16px 25px

  &-calendar
    display inline-block
    width 250px
    color $dark
    position relative
    padding-left 63px
    @media(max-width $md)
      width 50%
      margin 0
    @media(max-width $sm)
      width 100%
    
    strong,
    small
      display block
    
    strong
      font-size 22px
      font-weight 900
    small 
      font-size 13px
      font-weight 700

    &:before
      content ''
      position absolute
      width 41px
      height 41px
      background-size 41px 41px
      background-repeat no-repeat
      background-image url('../images/icon-calendario.png')
      top calc(50% - 20.5px)
      left 0
  
  .btn.btn-primary-dark
    height 39px
    width 149px
    @media(max-width $md)
      width 100%
      margin-top 20px
  
  .share-list
    @media(max-width $md)
      width 100%
      margin-top 20px
      margin-left 0

#article-section
  color $dark
  font-size 15px
  text-align justify
  line-height 25px

  .btn-dark-outline
    width 152px
    height 39px
    margin 0 auto
    display flex
  
  .app-curso-title
    font-size 25px
    text-transform uppercase
    color $primary
    font-weight 900
    letter-spacing 2px
    margin-bottom 40px
    text-align left


  .img-float
    float left
    max-width 100%
    margin-right 10px
    margin-bottom 0
    @media(max-width $sm)
      width 100%
      margin-right 0

  .app-article-body
    border-bottom 3px solid #bfc0bf
    padding 40px 0
    margin-bottom 20px

    &.curso
      border-bottom 0

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong
      color $secondary
      text-transform uppercase

    ul
      margin-top 34px

    li
      display block
      list-style none
      &:before
        position relative
        content '•'
        margin-right 10px
        color $secondary
      & + li
        margin-top 20px

    
    p + p
      margin-top 34px
    
    img
      max-width 100%
      height auto
    
    .row
      row()

    .column-50
      column(50%)
      @media(max-width $sm)
        column(100%)
    .column-100
      column(100%)
      margin-top 30px

  .container
    @media(max-width $md)
      padding-left 10px
      padding-right 10px
  
  

  iframe
    width 726px !important
    height 409px !important
    margin 40px auto
    display block
    @media(max-width 726px)
      width 100% !important
    @media(max-width $xs)
      width 100% !important
      height 309px !important

.app-disqus-container
  wrapper()
  width 653px
  margin 0 auto

  @media(max-width 653px)
    width 100%
    padding 0 10px

.share
  wrapper()
  display flex
  flex-flow row wrap
  align-items center
  justify-content flex-start
  .card-date
    margin-left 40px
    margin-top 0
    @media(max-width $sm)
      margin-left 10px
  &-list
    margin-left 40px
    @media(max-width $sm)
      width 100%
      margin-left 0
      margin-top 20px
  &-item
    display inline-block
    list-style none
    color #a0a0a0
    margin 0 4px
    font-size 12px
    a
      width 26px
      height 26px
      display flex
      flex-flow row wrap
      align-items center
      justify-content center
      background-color $secondary
      color #FFF
      &:hover
        background-color $primary

