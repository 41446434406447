*, *:before, *:after
  padding 0
  margin 0
  box-sizing: border-box;

*:focus
  outline none
  -webkit-tap-highlight-color transparent

h1, h2, h3, h4, h5, h6
  -webkit-margin-before 0
  -webkit-margin-after 0
  -webkit-margin-start 0px
  -webkit-margin-end 0px

.sr-only
  position absolute
  width 1px
  height 1px
  padding 0
  margin -1px
  overflow hidden
  clip rect(0,0,0,0)
  border 0
