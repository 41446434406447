.btn
  width auto
  height 50px
  padding 0
  border 0
  display inline-flex
  align-items center 
  justify-content center
  text-align center
  white-space nowrap
  vertical-align middle
  -webkit-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none
  font-size 14px
  line-height 1.5
  border-radius 0
  transition all ease .3s
  text-transform uppercase
  font-weight 700
  letter-spacing 1px
  cursor pointer

  &-block
    display flex
    width 100%

  &-primary
    background $primary
    color #FFF
    &:hover
      background-color $primary_hover
      color $secondary  
  
  &-primary-dark
    background-color $primary_hover
    color $secondary

  &-secondary
    background-color $secondary
    color #FFF
    &:hover
      background-color $primary_hover
      color $secondary  
  
  &-dark-outline
    border 2px solid $primary_hover
    background-color transparent
    color $primary_hover
    &:hover
      border 2px solid $primary_hover
      background-color $primary_hover
      color $alt