.app-bar
  background-color $primary
  height 50px
  position relative
  z-index 5
  &-container
    height 100%
    display flex
    flex-flow row wrap
    align-items flex-start
    justify-content flex-start
  
  &-agenda
    width calc(100% - 250px)
    height 50px
    color #fff
    text-transform uppercase
    letter-spacing 1px
    font-size 13px
    display flex
    align-items center
    @media(max-width $sm)
      width calc(100% - 180px)
    a:not(.btn)
      color #fff
      margin-left 5px
    strong
      color #00bef4
      font-weight normal
      @media(max-width $sm)
        display none

  
  .inscricao
    width 250px
    @media(max-width $sm)
      width 180px
  
  .facebook
    width 50px
    font-size 20px
    margin-right 25px