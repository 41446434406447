.app-footer
  wrapper()
  margin-top 100px
  padding-top 77px
  padding-bottom 62px
  background-color $primary
  border-bottom 10px solid $secondary
  color #FFF
  @media(max-width $sm)
    padding-top 27px
    padding-bottom 16px


  .container
    @media(max-width $md)
      padding-left 10px
      padding-right 10px

  .row
    row()
    @media(max-width $sm)
      row(-10px)
  .column
    column(25%)
    @media(max-width $sm)
      column(50%, 10px)
      margin 20px 0
    @media(max-width $xs)
      column(100%, 10px)
  
  &-title
    color $secondary
    text-transform uppercase
    font-size 14px
    font-weight 900
    letter-spacing 1px
    margin-bottom 16px
  
  span
    display block
    font-size 14px
    & + span
      margin-top 40px
    
    strong
      font-weight 700
    
    small
      font-size 12px
      strong
        color $secondary
  a
    color #FFF
    display block
    font-weight 700
    font-size 14px

    &.facebook
      width 33px
      height 33px
      display flex
      align-items center
      justify-content center
      background-color $primary_hover
      color $secondary
      margin-top 20px

  &-nav
    position relative
    &-list
      margin 0
    &-item
      display block
      list-style none
      & + .app-footer-nav-item
        margin-top 5px
    &-link
      color #FFF
      font-weight 900
      text-transform uppercase
      font-size 14px
      &:hover
        color $secondary