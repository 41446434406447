.form
  &-group
    margin-bottom 20px
    position relative
  
  &-error
    color $danger
    font-weight 700
    font-size 12px
    position absolute
    top 43px
    right 10px


  &-label
    color $primary_hover
    font-weight 700
    display block
    margin-bottom 10px
    font-size 15px
    cursor pointer
    
  &-control
    width 100%
    height 45px
    border 2px solid $alt
    background-color $alt
    padding 10px
    transition all ease .2s
    font-family 'Lato' sans-serif
    color $dark
    font-size 13px

    &.textarea
      min-height 90px

    &.danger
      border-color $c30000

    &:focus
      border-color $secondary

