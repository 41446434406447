.app-section
  padding 0
  margin 0
  wrapper()

.app-main.index,
.app-main.artigo
  .app-section
    padding 92px 0
    &.no-padding
      padding-top 0 !important
    
    @media(max-width $md)
      padding 72px 0
    @media(max-width $sm)
      padding 52px 0
    @media(max-width $xs)
      padding 32px 0

    &.bg-alt
      background-color $alt

    &-header
      margin-bottom 34px