.card
  &-header
    position relative
    width 100%
    &.cover
      height 208px
      background-size cover
      background-position center
      background-repeat no-repeat
  
  &-body
    background-color $alt
    padding 26px 34px
    @media(max-width $xs)
      padding 10px
  
  &-title
    color $primary_hover
    margin-top 12px
    text-transform uppercase
    font-size 15px
  
  &-date
    position relative
    display block
    margin-top 12px
    padding-left 28px
    color $dark
    font-size 12px
    &:before
      position absolute
      content ''
      width 18px
      height 18px
      display block
      top calc(50% - 9px)
      left 0
      background-size 18px 18px
      background-position center
      background-repeat no-repeat
      background-image url('../images/icon-calendario.png')
