.app-nl
  wrapper()
  margin-top 20px
  &-group
    position relative
  
  &-control
    width calc(100% - 33px)
    height 33px
    padding 10px
    font-family 'Lato', sans-serif
    color $dark
    background-color #FFF
    border 2px solid #FFF
    &:focus
      border-color $secondary
    &.error
      border-color $danger !important

  &-submit
    width 33px
    height 33px
    background-color $secondary
    color #fff
    display flex
    align-items center
    justify-content center
    position absolute
    top 0
    right 0
    border 0
    cursor pointer

  .form-error
    background-color $danger
    color #FFF
    display flex
    width 100%
    margin 0
    position relative
    bottom 0
    left 0
    top 10px
    right 0
    height 33px
    padding 10px
    float none
    align-items center
    justify-content center
