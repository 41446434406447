.tag
  display inline-block
  width auto
  padding 8px 11px
  border-radius 0
  font-size 12px
  font-weight 700
  text-transform uppercase

  &-primary
    background-color $primary
    color #FFF
  
  &-primary-dark
    background-color $primary_hover
    color #FFF
  
  &-secondary
    background-color $secondary
    color #FFF
