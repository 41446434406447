#agenda-interna
  .container
    @media(max-width $md)
      padding-left 10px
      padding-right 10px
  
  .agenda
    wrapper()
    height auto
    &-card
      height auto
      & + .agenda-card
        margin-top 20px
      
      &.active
        .agenda-card
          &-title
            color #FFF
          &-header
            background-color $secondary
            .tag-secondary
              color $secondary
              background-color #FFF

          &-body
            display block
          
          &-calendar
            color #FFF
            &:before
              background-image url('../images/icon-calendario-hover.png')
          
          &-toggle
            background-color $alt
            color $dark
            &:before
              content '\f068'
      
      &-title
        display inline-block
        margin-left 35px
        color $secondary
        font-weight 700
        text-transform uppercase
        font-size 18px
        @media(max-width $md)
          display block
          width 100%
          margin-left 12px
        @media(max-width $xs)
          margin-left 0
          margin-top 10px
          margin-bottom 10px

      &-calendar
        display inline-block
        width 100%
        color $dark
        position relative
        padding-left 63px
        @media(max-width $md)
          margin-left 12px
        @media(max-width $xs)
          margin-left 0
          margin-top 10px
        
        strong,
        small
          display block
        
        strong
          font-size 22px
          font-weight 900
        small 
          font-size 13px
          font-weight 700

        &:before
          content ''
          position absolute
          width 41px
          height 41px
          background-size 41px 41px
          background-repeat no-repeat
          background-image url('../images/icon-calendario.png')
          top calc(50% - 20.5px)
          left 0
      
      &-header
        background-color $alt
        width 100%
        min-height 117px
        display flex
        flex-flow row wrap
        align-items center
        justify-content flex-start
        padding-left 95px
        padding-right 32px
        padding-top 12px
        padding-bottom 12px
        position relative
        cursor pointer
        @media(max-width $md)
          padding-left 75px
          padding-right 12px
        @media(max-width $xs)
          padding-left 6px
          padding-right 6px
        .tag
          position absolute
          top calc(50% - 15.5px)
          left 32px
          @media(max-width $md)
            top 14px
            left 12px
          @media(max-width $xs)
            position relative
            top 0
            left 0

        &-left
          width 55%
          @media(max-width $md)
            width 100%
        &-right 
          width 45%
          @media(max-width $md)
            width 100%
      
      &-body
        display none
        padding 54px 35px
        background-color $alt
        color $dark
        font-size 16px
        @media(max-width $sm)
          padding 20px 10px

      &-toggle
        width 50px
        height 50px
        background-color $secondary
        color #FFF
        display flex
        justify-content center
        align-items center
        border 0
        border-radius 50%
        cursor pointer
        position absolute
        top calc(50% - 25px)
        right 32px
        @media(max-width $md)
          display none

        &:before
          content '\f067'
          font-family FontAwesome
          -webkit-font-smoothing: antialiased;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          position relative
          display inline
          font-size 22px
      
      &-row
        row()
      &-column-img
        column(348px)
        @media(max-width $md)
          column(100%)
        img
          max-width 100%
          height auto
      &-column-text
        column(calc(100% - 348px))
        @media(max-width $md)
          column(100%)
          margin-top 30px
        p + p
          margin-top 30px

        p:last-child
          margin-bottom 60px 

        
        .btn-dark-outline
          width 265px
          height 40px
          @media(max-width $md)
            width 100%