#cursos-interna
  .container
    @media(max-width $md)
      padding-left 10px
      padding-right 10px
  
  .cursos
    wrapper()
    height auto
    &-card
      height auto
      & + .cursos-card
        margin-top 20px
      
      &.active
        .cursos-card
          &-title
            color #FFF
          &-header
            background-color $primary_hover

          &-body
            display block
          
          &-calendar
            color #FFF
            &:before
              background-image url('../images/icon-calendario-hover.png')
      
      &-title
        display inline-block
        margin 0
        color $primary_hover
        font-weight 700
        text-transform uppercase
        font-size 18px
        @media(max-width $md)
          display block
          width 100%
        @media(max-width $xs)
          margin-top 10px
          margin-bottom 10px

      &-calendar
        display inline-block
        width 250px
        color $dark
        position relative
        padding-left 63px
        @media(max-width $md)
          width 50%
          margin 0
        @media(max-width $sm)
          width 100%
        
        strong,
        small
          display block
        
        strong
          font-size 22px
          font-weight 900
        small 
          font-size 13px
          font-weight 700

        &:before
          content ''
          position absolute
          width 41px
          height 41px
          background-size 41px 41px
          background-repeat no-repeat
          background-image url('../images/icon-calendario.png')
          top calc(50% - 20.5px)
          left 0
      
      &-header
        background-color $alt
        width 100%
        min-height 117px
        display flex
        flex-flow row wrap
        align-items center
        justify-content flex-start
        padding-left 32px
        padding-right 32px
        padding-top 12px
        padding-bottom 12px
        position relative
        @media(max-width $md)
          padding-left 12px
          padding-right 12px
        @media(max-width $xs)
          padding-left 6px
          padding-right 6px

        &-left
          width 55%
          @media(max-width $md)
            width 100%
        &-right 
          width 45%
          display flex
          flex-flow row wrap
          align-items center
          justify-content space-between
          @media(max-width $md)
            width 100%
            margin-top 20px
      
        .btn-dark-outline
          width 150px
          height 40px
          @media(max-width $md)
            width 50%
          @media(max-width $sm)
            width 100%
            margin-top 10px