.app-section-title
  width auto
  color $primary_hover
  display inline-block
  text-transform uppercase
  font-size 23px
  padding-left 82px
  padding-bottom 15px
  position relative
  font-weight 900
  @media(max-width $md)
    padding-left 72px
  @media(max-width $sm)
    padding-left 52px
    font-size 20px
    padding-bottom 10px
  
  &.no-icon
    padding-left 0
    &:before
      display none
    &:after
      left 0
    @media(max-width $md)
      padding-left 0
    @media(max-width $sm)
      padding-left 0

  &:before,
  &:after
    content ''
    position absolute
  
  // icon
  &:before
    top calc(50% - 27.5px)
    left 0
    width 55px
    height 55px
    background-size 55px 55px
    background-position center 
    background-repeat no-repeat
    @media(max-width $sm)
      width 40px
      height 40px
      background-size 40px 40px
      top calc(50% - 20px)
  &.icon-blog:before
    background-image url('../images/icon-blog-page.png')
  &.icon-cursos:before
    background-image url('../images/icon-cursos-index.png')
  &.icon-agenda:before
    background-image url('../images/icon-agenda-index.png')
  &.icon-familia:before
    background-image url('../images/icon-familia-index.png')

  // border
  &:after
    width 80px
    height 3px
    bottom 0
    left 82px
    background-color #9f9f9f
    border-radius 3px
    @media(max-width $md)
      left 72px
    @media(max-width $sm)
      left 52px
      width 70px