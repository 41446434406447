.app-pagination
  width 100%
  text-align center
  padding 0 10px
  margin-top 75px
  &-list
    max-width 100%
    width auto 
    display inline-block
    margin 0 auto
    li
      display inline-block
      margin 5px
      a
        color $primary_hover
        display flex
        align-items center
        justify-content center
        width 47px
        height 47px
        font-size 15px
        background-color $alt
        font-weight 900
        &:hover
          background-color $secondary
          color #FFF
      &.active
        a
          background-color $secondary
          color #FFF

