.t
  &-left
    text-align left !important
  &-center
    text-align center !important
  &-right
    text-align right !important
  &-justify
    text-align justify !important


// Mixins Text
textOverflow(w= 100%)
  white-space nowrap
  text-overflow ellipsis
  width w
  display block
  overflow hidden
