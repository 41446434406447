#blog

  .container
    @media(max-width $md)
      padding 10px

  .blog-row
    row(-20px)
    @media(max-width $sm)
      row(-10px)
    .blog-column
      column(33.333%, 20px)
      margin 20px 0
      @media(max-width $sm)
        column(50%, 10px)
        margin 10px 0
      @media(max-width $xs)
        column(100%, 10px)