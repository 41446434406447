.breadcrumb
  wrapper()
  background-color $alt
  padding 40px 0
  margin-bottom 60px

  &-container
    display flex
    flex-flow row wrap
    align-items center
    justify-content space-between
    margin-left m
    margin-right m
    height auto
    width 100%
    @media(max-width $md)
      padding-left 10px
      padding-right 10px
    
  &-title
    width auto
    color $primary_hover
    display inline-block
    text-transform uppercase
    font-size 23px
    padding-left 82px
    padding-bottom 15px
    position relative
    font-weight 900
    @media(max-width $md)
      padding-left 72px
    @media(max-width $sm)
      padding-left 52px
      font-size 20px
      padding-bottom 10px
    &:before,
    &:after
      content ''
      position absolute
    
    // icon
    &:before
      top calc(50% - 27.5px)
      left 0
      width 55px
      height 55px
      background-size 55px 55px
      background-position center 
      background-repeat no-repeat
      @media(max-width $sm)
        width 40px
        height 40px
        background-size 40px 40px
        top calc(50% - 20px)
    
    &.icon-contato:before
      background-image url('../images/icon-contato.png')
    &.icon-agenda:before
      background-image url('../images/icon-agenda-page.png')
    &.icon-blog:before
      background-image url('../images/icon-blog-page.png')
    &.icon-cursos:before
      background-image url('../images/icon-cursos-page.png')

    // border
    &:after
      width 80px
      height 3px
      bottom 0
      left 82px
      background-color #9f9f9f
      border-radius 3px
      @media(max-width $md)
        left 72px
      @media(max-width $sm)
        left 52px
        width 70px

  &-list
    width auto
    text-align right
    &-item
      display inline-block
      list-style none
      margin-left 20px
      text-transform uppercase
      font-size 15px
      @media(max-width $sm)
        margin-left 10px
        font-size 13px
      a
        color $dark
        &:hover
          color $primary
      & + .breadcrumb-list-item:before
        content '•'
        margin-right 20px
        color $secondary
        @media(max-width $sm)
          margin-right 10px
    
