.app-navigation
  padding 49px 0 40px 0
  transition all ease .5s
  position relative
  z-index 5
  &.menu-active
    padding-right 260px
    transition all ease .5s
    @media(max-width $sm)
      padding-right 190px
    @media(max-width $xs)
      padding-right 180px
      .app-navigation-logo
        display none
      .app-navigation-container
        justify-content flex-end
  &-container
    display flex
    width 100%
    height 73px
    justify-content space-between
    align-items flex-start
    // align-items center
    @media(max-width $md)
      padding 0 10px
  
  &-logo
    width auto
    display inline-block
    height auto
    a
      display block
      line-height 0
    img
      width 293px
      height auto
      @media(max-width $sm)
        width 250px
      @media(max-width $xs)
        width 200px

.app-nav
  width 720px
  @media(max-width $md)
    display block
    position fixed
    top 50px
    right 0
    height calc(100% - 50px)
    width 250px
    background-color $alt
    transform translateX(250px)
    transition all ease .5s
    z-index 100
    &.active
      transform translateX(0)
      transition all ease .5s
  @media(max-width $sm)
    width 180px
    transform translateX(180px)
  
  &-list
    margin 0
    padding 0
    width 100%
    display flex
    justify-content space-between
    padding-top 15px
    @media(max-width $md)
      display block
      padding 0
    &-item
      display inline-block
      list-style none
      color $primary
      text-transform uppercase
      @media(max-width $md)
        display block
        width 100%
        padding-top 15px
        padding-left 15px
        padding-right 15px
    &-link
      color $primary
      font-size 14px
      font-weight 700
      transition all ease .3s
      &:hover,
      &.active
        color $secondary
  
  &-toggle
    display none
    @media(max-width $md)
      display block
      &-button
        display flex
        border 0
        width 50px
        height 50px
        background-color $primary
        color $secondary
        cursor pointer
        align-items center
        justify-content center
        font-size 30px
        .fa-bars
          display block
        .fa-times
          display none
        &.active
          .fa-bars
            display none
          .fa-times
            display block
        @media(max-width $xs)
          width 35px
          height 35px
          font-size 20px