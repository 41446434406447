.app-banner
  position absolute
  top 50px
  left 0
  right 0
  width 100%
  height 515px
  z-index 1
  .swiper-slide
    height 515px !important
    position relative
  &-cover
    position absolute
    top 0
    left 0
    right 0
    width 100%
    height 100%
    z-index 1
    background-size cover
    background-position center
    background-repeat no-repeat
  &-body
    padding-top 240px
    position relative
    z-index 2
    @media(max-width $md)
      padding-left 10px
      padding-right 10px
    img
      max-width 100%

.swiper-pagination
  &-bullet
    width 13px
    height 13px
    opacity 1
    background-color #ecefec
    & + .swiper-pagination-bullet
      margin 0 6.5px
    &-active
      background-color $secondary
  