@import "reset.styl"
@import "helpers/**/*.styl"
@import "components/**/*.styl"

body
  font-family 'Lato', sans-serif
  max-width 100%
  min-height 100vh
  height auto
  overflow-x hidden
  overflow-y auto
  background-color #FFF
  font-size 100%
  &.menu-active
    overflow-y hidden

a
  text-decoration none

main.app-main
  wrapper()

@font-face {
  font-family: FontAwesome;
  src: url('https://use.fontawesome.com/releases/v5.8.2/webfonts/fa-solid-900.woff2');
}
