#contato
  .container
    max-width 832px
    @media(max-width 842px)
      padding 0 10px
  
  .contato
    wrapper()
    
    &-infos
      row(-10px)
      font-size 13px
      margin-bottom 40px
      @media(max-width $sm)
        margin-bottom 20px
      &-title
        margin-bottom 17px
        color #9f9f9f
        text-transform uppercase
        @media(max-width $sm)
          display inline-block
          margin-right 10px

      .phone, .email, .social
        column(33.333%, 10px)
        @media(max-width $sm)
          column(100%, 10px)
      
      .phone, .email
        a
          color $primary_hover
          font-weight 700
      
      .social
        .btn
          width 25px
          height 25px
          color #FFF

  .form
    wrapper()
    padding-top 40px
    border-top 4px solid $alt
    @media(max-width $sm)
      padding-top 20px